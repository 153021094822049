var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VSpeedDial',{staticClass:"floating-nav",attrs:{"direction":'top',"right":true,"transition":'slide-y-reverse-transition'},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('VBtn',{style:({
        border: 'none',
        boxShadow: '0px 1px 10px',
        color: ('#11111133'),
      }),attrs:{"disabled":_vm.reloadLoading,"fab":"","input-value":false,"ripple":false},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[(_vm.reloadLoading)?_c('img',{attrs:{"src":require("@/assets/svg/nav-menu_refresh.svg")}}):(_vm.active)?_c('img',{attrs:{"src":require("@/assets/svg/floating-menu_close.svg")}}):_c('img',{attrs:{"src":require("@/assets/svg/floating-menu_burger.svg")}})])]},proxy:true}]),model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},_vm._l((_vm.togglerItems),function(item,key){return _c('VBtn',{key:key+'_'+item.title,style:({
      boxShadow: 'none',
    }),attrs:{"active-class":'none',"color":key === _vm.activeId ? '#0086E7' : undefined,"elevation":0,"exact-active-class":'none',"fab":"","replace":"","ripple":false,"small":"","to":item.to},on:{"click":() => item.hasOwnProperty('action') && item.action()}},[(key === _vm.activeId)?_c('img',{attrs:{"src":item.iconActive}}):_c('img',{attrs:{"src":item.iconDisabled}})])}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }