import { render, staticRenderFns } from "./WayShareIcon.vue.js?vue&type=template&id=71e5fda7!=!../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./WayShareIcon.vue?vue&type=template&id=71e5fda7"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports