import { setCustomBackArrowVisible, setCustomBackArrowMode, setCustomBackArrowOnClickHandler, setHeaderMenuItemClickHandler, setHeaderMenuItems } from "@/helpers/bridge";

export const backNavigateQueue = {
  handlerFns: [],
  menuActive: false,
  /**
  * Set new back
  * @param fn function to exec on 'back'
  **/
  set(fn){
    console.log('backNavigateQueue.set');
    if (typeof fn !== 'function') throw new Error('handler should be a function, got ' + typeof fn);
    if (!this.handlerFns.length){
      window.androidHandleBack = this.exec;
      setCustomBackArrowVisible(true);
    }
    this.handlerFns = [ fn ];
  },
  /**
  * Add back navigation which will be poped after execution
  * @param fn function to exec on 'back'
  **/
  push(fn){
    console.log('backNavigateQueue.push');
    if (typeof fn !== 'function') throw new Error('handler should be a function, got ' + typeof fn);
    if (!this.handlerFns.length){
      setCustomBackArrowVisible(true);
    }
    this.handlerFns.push(fn);
  },
  /**
  * Executes last handler fn and removes it
  */
  exec(){
    console.log('backNavigateQueue.exec');
    if (!this.handlerFns.length){
      setCustomBackArrowVisible(false);
    } else if (this.handlerFns.length){
      this.handlerFns[this.handlerFns.length-1]();
    } else {
      console.log('No func to exec');
    }
  },
  pop(){
    console.log('backNavigateQueue.pop');
    this.handlerFns.pop();
    if (!this.handlerFns.length){
      setCustomBackArrowVisible(false);
    }
  },
  clear(){
    console.log('backNavigateQueue.clear');
    this.handlerFns = [];
    delete window.androidHandleBack;
    setCustomBackArrowVisible(false);
  },
  async init(){
    console.log('backNavigateQueue.init');
    await setCustomBackArrowMode(true);
    await setCustomBackArrowOnClickHandler(this.exec.bind(this));
  },
};

const getMenuItems = () => {
  return [
    {
      id: "reload",
      icon: 'Reload',
    },
  ];
};

export const initHeaderMenuItems = async ({handler}) => {
  await setHeaderMenuItems(getMenuItems());
  setHeaderMenuItemClickHandler(handler);
};

export default {
  backNavigateQueue,
  initHeaderMenuItems,
};