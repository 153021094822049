<template>
  <VSpeedDial
    v-model="active"
    class="floating-nav"
    :direction="'top'"
    :right="true"
    :transition="'slide-y-reverse-transition'"
  >
    <template v-slot:activator>
      <VBtn
        v-model="active"
        :disabled="reloadLoading"
        fab
        :input-value="false"
        :ripple="false"
        :style="{
          border: 'none',
          boxShadow: '0px 1px 10px',
          color: ('#11111133'),
        }"
      >
        <img
          v-if="reloadLoading"
          src="@/assets/svg/nav-menu_refresh.svg"
        >
        <img
          v-else-if="active"
          src="@/assets/svg/floating-menu_close.svg"
        >
        <img
          v-else
          src="@/assets/svg/floating-menu_burger.svg"
        >
      </VBtn>
    </template>
    <VBtn
      v-for="(item, key) in togglerItems"
      :key="key+'_'+item.title"
      :active-class="'none'"
      :color="key === activeId ? '#0086E7' : undefined"
      :elevation="0"
      :exact-active-class="'none'"
      fab
      replace
      :ripple="false"
      small
      :style="{
        boxShadow: 'none',
      }"
      :to="item.to"
      @click="() => item.hasOwnProperty('action') && item.action()"
    >
      <img
        v-if="key === activeId"
        :src="item.iconActive"
      >
      <img
        v-else
        :src="item.iconDisabled"
      >
    </VBtn>
  </VSpeedDial>
</template>

<script>
import { mapState } from 'vuex';
import { backNavigateQueue } from "@/helpers/app";

export default {
  name: "FloatingNav",
  data(){
    return {
      active: false,
      reloadLoading: false,
      togglerItems: [
        {
          title: "Обновить",
          iconActive: require('@/assets/svg/nav-menu_refresh.svg'),
          iconDisabled: require('@/assets/svg/nav-menu_refresh.svg'),
          action: async () => {
            if (!this.reloadLoading){
              this.reloadLoading = true;
              try {
                localStorage.removeItem('viewed-items');
              } finally {
                location.reload();
              }

            }
          },
        },
        {
          title: "Уведомления",
          iconActive: require('@/assets/svg/footer_notification--active.svg'),
          iconDisabled: require('@/assets/svg/footer_notification--disabled.svg'),
          action: () => {
            if (this.show_burger_content) {
              this.$store.dispatch('layout/toggleBurgerContent');
            }
            this.$router.replace({
              name: 'NotificationsSettingsPage',
            });
          },
        },
        {
          title: "Главная",
          iconActive: require('@/assets/svg/footer_home--active.svg'),
          iconDisabled: require('@/assets/svg/footer_home--disabled.svg'),
          action: () => {
            this.$router.replace({
              name: 'MainPage',
            });
          },
        },
        {
          title: "Back",
          iconActive: require('@/assets/svg/way_arrow-left.svg'),
          iconDisabled: require('@/assets/svg/way_arrow-left.svg'),
          action: () => {
            backNavigateQueue.exec();
          },
        },
      ],
    };
  },
  computed: {
    activeId(){
      return {
        'MainPage': 3,
        'NotificationsSettingsPage': 2,
      }[this.$route.name];
    },
    ...mapState('layout', [ 'show_burger_content']),
  },
};
</script>
<style lang="scss">
.layout{
  .v-speed-dial{
    position: fixed;
    .v-btn{
      border: 1px solid #DDE1E5;
      box-shadow: 0px 1px 10px rgba(102, 122, 139, 0.12);
      &:before{
        opacity: 0 !important;
      }
    }
  }
}
.floating-nav{
  bottom: 128px;
  z-index: 999;
  .floating-nav__item{
    position: relative;
    svg{
      &:focus{
        outline: none;
      }
    }
  }
}
</style>
