import { render, staticRenderFns } from "./SurveyBackIcon.vue.js?vue&type=template&id=0fea1b18!=!../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./SurveyBackIcon.vue?vue&type=template&id=0fea1b18"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports