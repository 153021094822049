import { render, staticRenderFns } from "./BellIcon.vue.js?vue&type=template&id=3e5680fe!=!../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./BellIcon.vue?vue&type=template&id=3e5680fe"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports