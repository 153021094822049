import baseApi from "@/helpers/baseApi";
import { detectedBridgePlatform, enableNotifications, disableNotifications } from "@/helpers/bridge";
import { translateLang } from "@/plugins/i18n.js";

const state = () => ({
  requestLoading: false,
  status: window.localStorage.getItem('n'),
  sendsApiRequest: true,
});

const mutations = {
  setEnabled(state){
    state.status = "enabled";
  },
  setDisabled(state){
    state.status = "disabled";
  },
};

const actions = {

  async setEnabled({ commit, dispatch, state, rootState }){
    state.requestLoading = true;
    try {
      if (detectedBridgePlatform){
        const result = await enableNotifications();
        console.log('disabled result = ', result);
        window.localStorage.setItem('n', 'enabled');
        await commit('setEnabled');
      } else {
        throw(translateLang('store_notifications_no-orta', rootState.layout.language));
      }

    } catch(err) {
      console.log('err', err);
      dispatch('layout/alert',  translateLang('notifications_not_supported'), { root: true });
    } finally {
      state.requestLoading = false;
    }
  },

  async setDisabled({ commit, dispatch, state, rootState }){
    state.requestLoading = true;
    try {
      if (detectedBridgePlatform){
        const result = await disableNotifications();
        console.log('disabled result = ', result);
      } else {
        throw(translateLang('store_notifications_no-orta', rootState.layout.language));
      }

      let sendsApiRequest = !rootState.layout.is_select_auth_form;
      if (sendsApiRequest){
        await baseApi.post('/api/notification/disable_push/').catch(() => {});
      }

      window.localStorage.setItem('n', 'disabled');

      await commit('setDisabled');
    } catch(err) {
      dispatch('layout/alert', err?.reason, { root: true });
    } finally {
      state.requestLoading = false;
    }
  },

  async switchStatus({ dispatch, state }){
    if (state.status === 'enabled'){
      console.log('setDisabled');
      await dispatch('setDisabled');
    } else {
      console.log('setEnabled');
      await dispatch('setEnabled');
    }
  },
};

export const notifications = {
  namespaced: true,
  state,
  mutations,
  actions,
};