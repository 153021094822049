<template>
  <div
    class="loader-screen"
    :class="{
      /* 'loader-screen--dark': theme === 'dark', */
      'loader-screen--light': true
    }"
  >
    <div class="loader-screen__logo-container">
      <BaseIcon
        class="logo--dark"
        name="erg"
      />
    </div>
    <div
      v-if="errorShow"
      class="loader-screen__error"
    >
      <template v-if="!reloadClicked">
        <p>{{ $translate('app-loader_error_title') }}</p>

        <template v-if="errorTextArr && errorTextArr.length">
          <p
            v-for="(errorText, k) in errorTextArr"
            :key="k"
          >
            {{ errorText }}
          </p>
        </template>
      </template>
      <VBtn
        class="mt-16px"
        :disabled="reloadClicked"
        outlined
        @click="handleReloadClick"
      >
        {{ $translate('app-loader_error_reload') }}
        <img
          class="ml-4px"
          :class="{
            'reload-icon--rotating': reloadClicked
          }"
          src="@/assets/svg/nav-menu_refresh.svg"
        >
      </VBtn>
    </div>
    <div class="loader-screen__bottom">
      <p class="text-center small">
        from
      </p>
      <h5 class="text-center">
        BTS
      </h5>
    </div>
  </div>
</template>

<script>
import { detectedBridgePlatform, getMe as getMeMobile } from "@/helpers/bridge";
import { authViaBridge } from "@/services/auth";

export default {
  name: 'AppLoaderScreen',
  props:{
    type: String,
  },
  data(){
    return {
      errorShow: false,
      errorCode: undefined,
      errorTextArr: [],
      me: undefined,
      reloadClicked: false,
    };
  },
  created(){
    this.authorization();
  },
  methods: {
    async authorization() {
      if (detectedBridgePlatform || process.env.NODE_ENV === "development") {
        this.getMe().then(async (meData) => {
        this.$store.dispatch('user/set', meData);

        await authViaBridge(meData);

        this.$emit("authenticated");
      }).catch((err) => {
        console.log('authorization err', err);
        this.errorShow = true;
        this.errorTextArr = [
          ...this.errorTextArr,
          err,
        ];
      });
      } else {
        console.log('authorization err');
        this.errorShow = true;
        this.errorTextArr = [
          ...this.errorTextArr,
          this.$translate('app-loader_error_description_no-orta'),
        ];
      }
    },
    async getMe(){
      let meData;
      if (detectedBridgePlatform){
        meData = await getMeMobile();
      } else {
        try {
          meData = JSON.parse(process.env.VUE_APP_ME_DATA);
        } catch {
          throw Error(".env.development.local.example -> .env.development.local");
        }
      }
      this.$store.dispatch('user/setOrtaMeData', meData);
      console.log('meData', meData);
      return meData;
    },

    async handleReloadClick(){
      if (!this.reloadClicked){
        this.reloadClicked = true;
        location.reload();
      }
    },
  },
};
</script>

<style lang="scss">
.loader-screen{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: linear-gradient(213.26deg, #9BABBA 11.27%, #7B8A98 102.62%), linear-gradient(180deg, #D3D4D7 0%, rgba(211, 212, 215, 0) 100%), #FFFFFF;
  color: white;
  >.loader-screen__logo-container{
    flex: 1 0 auto;
    width: 33%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px 0 0 0;
    .logo{
      fill: currentColor;
    }
    .logo:not(.animate-pulse){
      color: #FFF;
    }
    .animate-pulse {
      animation: pulse-white 0.7s infinite;
    }
    svg{
      &.logo--dark{
        width: 100%;
        height: auto;
        .dark-pulse-target{
          opacity: 1;
        }
        .dark-pulse-2-target{
          opacity: 1;
          color: #c5c7c8;
        }
      }
    }
  }
  >.loader-screen__error{
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    // color: white;
    text-align: center;
    margin: 0 16px;
  }
  >.loader-screen__bottom{
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 32px;
    user-select: none;
  }
  &.loader-screen--light{
    background: #FFF;
    color: #151C22;
    >.loader-screen__logo-container{
      .logo{
        color: #151C22;
        fill: currentColor;
      }
      .logo:not(.animate-pulse){
        color: rgb(21, 28, 34);
      }
      .animate-pulse {
        animation: pulse-dark 0.7s infinite;
      }
    }
  }
  &.loader-screen--dark{
    background: #282C32;
    >.loader-screen__logo-container{
      .logo{
        fill: currentColor;
      }
      .logo:not(.animate-pulse){
        color: #FFF;
      }
      .loader-screen__error{
        color: white;
      }
    }
  }
}
@keyframes pulse-white {
  0% {
    color: rgb(250, 250, 250);
  }
  50% {
    color: rgb(221, 221, 221);
  }
  100% {
    color: rgb(250, 250, 250);
  }
}

@keyframes pulse-dark {
  0% {
    opacity: 1;
  }
  // 25%{
  //   opacity: 0.75;
  // }
  50% {
    opacity: 0.8;
  }
  // 75%{
  //   opacity: 0.75;
  // }
  100% {
    opacity: 1;
  }
}
@keyframes pulse-dark-2 {
  0% {
    color: #c5c7c8;
  }
  50% {
    color: #999999;
  }
  100% {
    color: #c5c7c8;
  }
}
.reload-icon--rotating{
  animation: loader-rotating 2s linear infinite;
}

@keyframes loader-rotating {
  0% {
    transform: rotate(360deg);
  }
  25% {
    transform: rotate(270deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>
