<template>
  <Component :is="dynamicComponent" />
</template>

<script>
import { toPascalCase } from '@/utils/utils';

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    dynamicComponent() {
      return require(`./../icons/${this.toPascalCase(this.name)}Icon.vue`).default;
    },
  },
  methods: {
    toPascalCase,
  },
};
</script>