import { render, staticRenderFns } from "./FoodHistoryIcon.vue.js?vue&type=template&id=9c4409c4!=!../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./FoodHistoryIcon.vue?vue&type=template&id=9c4409c4"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports